.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 70px;
  width: 100%;
  position: fixed;
  top: 82%;
  left: 33%;
  background: transparent;
  z-index: 3;
  
}



.nav-links {
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  // margin-right: 50px;
  background: #46464650; 
  padding: 8px ; 
  border-radius: 30px; 
  
  backdrop-filter: blur(16px); 
  border: 0px solid rgba(255, 255, 255, 0.25); 
  

  a {
    
    background: transparent; 
    padding: 10px 20px;
    border-radius: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    transition: 0.3s ease-in-out;


    &:hover {
      text-decoration: none;
      color: #ffffff;
      transform: translateY(-20px); 
      opacity: 0;
      animation: moveFromBottom 0.5s ease forwards; 
    }
  
    &:not(:hover) {
      opacity: 1; 
      transform: translateY(0px); 
    }
  
    &:nth-child(4) {
      background-color: #19806C;
    }
  }
  
  @keyframes moveFromBottom {
    0% {
      transform: translateY(20px); 
      opacity: 0;
    }
    100% {
      transform: translateY(0px); 
      opacity: 1;
    }
  

  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none; 
  }
}


// .header {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 20px 70px;
//   width: 100%;
//   z-index: 1;
//   position: fixed;

//   @media (max-width: 768px) {
//     padding: 20px 20px; 
//     justify-content: center; 
//   }
// }

// .logo-img {
//   width: 200px;
//   height: auto;
  
//   margin-top: 40px;
  

//   @media (max-width: 768px) {
//     // width: 50px; 
//     margin-left: -70%; 
//     margin-top: 10px; 
//   }
// }

// // .nav-links {
// //   display: flex;
// //   justify-content: center;
// //   gap: 70px;
// //   margin-top: 80px;

// //   a {
// //     color: #fff !important;
// //     text-decoration: none;
// //     font-size: 16px;
// //     font-weight: bold;
// //     letter-spacing: 1px;

// //     &:hover {
// //       text-decoration: none;
// //     }
// //   }

// //   @media (max-width: 768px) {
// //     display: none; 
// //   }
// // }

// // .burger-menu {
// //   position: absolute;
// //   right: -20%; 
// //   top: 7px; 
// //   cursor: pointer;
// //   z-index: 9999;
// //   fill: white; 
// //   stroke: #fff; 

// //   @media (max-width: 768px) {
// //     display: block; 
// //   }
// // }

// // .nav-links-mobile {
// //   display: none; 
// //   position: fixed; 
// //   top: 0; 
// //   right: 0; 
// //   background-color: rgb(0, 0, 0);
// //   z-index: 12;
// //   width: 100%; 
// //   height: 50vh; 
// //   border-bottom-left-radius: 40px;
// //   border-bottom-right-radius: 40px;
// //   flex-direction: column; 
// //   justify-content: center; 
// //   align-items: center; 
// //   gap: 50px; 
// //   // z-index: 1000; 
// //   transition: all 0.5s ease; 

// //   a {
// //     color: #fff !important;
// //     text-decoration: none;
// //     font-size: 16px;
// //     font-weight: bold;
// //     letter-spacing: 1px;

// //     &:hover {
// //       text-decoration: none;
// //     }
// //   }

// //   @media (max-width: 768px) {
// //     display: flex; 
// //   }
// // }