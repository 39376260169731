.bganimation-box {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; 
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://betdevelopers.s3.us-east-1.amazonaws.com/bd-bg.png') no-repeat center center;
  background-size: cover;
  animation: bgAnimation 10s infinite;
}

@keyframes bgAnimation {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.content {
  position: relative;
  z-index: -1;
  color: white; /* Text color for content inside the box */
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
