.customer-review-container {
  background-color: transparent;
  padding: 5rem 2rem;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins, sans-serif";

  @media (min-width: 600px) {
    padding: 13rem 15rem;
  }
}

.customer-review-title {
  font-weight: normal;
  text-transform: capitalize;
  font-family: "Poppins, sans-serif";
  margin-bottom: 1rem;
  width: 100%;
  font-size: 36px;
  margin-left: -69%;

  @media (min-width: 600px) {
    width: 30%;
    font-size: 48px;
  }

  @media (max-width: 600px) {
    position: relative;
    margin-left: 39%; 
  }
}

.customer-review-subtitle {
  margin-top: 2rem;
  margin-left: -61%;
  opacity: 0.9;
  max-width: 500px;
  font-family: "Poppins, sans-serif";
  margin-bottom: 3rem;

  @media (max-width: 600px) {
    position: relative !important;
    margin-left: 31% !important; 
  }
}

  
  .review-cards-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-top: 0;
    height: 340px;
    flex-direction: column;
  
    @media (min-width: 600px) {
      margin-left: 50%;
      margin-top: -20%;
    }
  }
  
  .review-card-background {
    position: absolute;
    width: 550px;
    height: 340px;
    border: 1px solid rgba(158, 158, 158, 0.35);
    border-radius: 2rem;
    transform: rotate(11deg);
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.5);
    z-index: 1;
  
    &:nth-child(1) {
      background-color: #135d4a;
    }
  
    &:nth-child(2) {
      background-color: #030A09;
      transform: rotate(22deg);
    }
  }
  
  .review-card {
    background-color: #112825;
    color: white;
    padding: 1rem;
    max-width: 500px;
    min-height: 330px;
    border-radius: 2rem;
    border: 1px solid rgba(158, 158, 158, 0.35);
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.5);
    text-align: left;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  
    .star-icon {
      color: #FFD700;
    }
  
    .review-avatar {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      overflow: hidden;
    }
  
    .review-name {
      font-weight: bold;
      text-transform: capitalize;
      font-family: "Poppins, sans-serif";
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      font-size: 20px;
  
      @media (min-width: 600px) {
        font-size: 24px;
      }
    }
  
    .review-text {
      opacity: 1;
      line-height: 1.6;
      font-family: "Poppins, sans-serif";
      transition: opacity 0.5s ease-in-out;
      font-size: 14px;
  
      @media (min-width: 600px) {
        font-size: 16px;
      }
    }
  
    .progress-bar {
      height: 4px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 2px;
      overflow: hidden;
      margin-top: auto;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
  
      .progress {
        width: 0%;
        height: 100%;
        background-color: white;
        transition: width 0.5s ease-in-out;
      }
    }
  }