
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  

  body {
    font-family: 'Comodo', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
  }

  body.custom-cursor {
    cursor: url("https://betdevelopers.s3.us-east-1.amazonaws.com/cursoricon.png") 8 8, auto; 
  }
 
  
  .app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
  }
  
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  a:hover {
    color: #007bff;
    text-decoration: underline;
  }
  
  
  .text-center {
    text-align: center;
  }
  
  .margin-top {
    margin-top: 20px;
  }

  .app {
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  