.box-transforming-container {
  position: relative;
  top: -2%;
  width: 100%;
  height: auto; // Adjusted for mobile
  min-height: 500px; // Ensure a minimum height
  background: linear-gradient(180deg, rgb(1, 46, 35), rgb(0, 12, 10));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(177, 177, 177, 0.35);
  backdrop-filter: blur(20px);
  padding: 2rem 1rem; // Added padding for mobile

  @media (max-width: 768px) {
    height: auto; // Adjust height for smaller screens
    padding: 1.5rem 1rem; // Reduce padding on mobile
    border-radius: 20px; // Slightly smaller border radius for mobile
    margin-top: 10%; // Add margin to center the box on mobile
  }
}

.box-transforming-title {
  position: relative;
  top: -12%;
  left: 1%;
  color: white;
  font-weight: bold;
  font-family: "Poppins, sans-serif";
  font-size: 2.5rem; // Default font size
  text-align: center; // Center text on mobile
  margin-bottom: 1rem; // Add spacing below the title

  @media (max-width: 768px) {
    top: 0; // Reset top position for mobile
    left: 0; // Reset left position for mobile
    font-size: 2rem; // Smaller font size for mobile
    margin-bottom: 0.5rem; // Reduce spacing on mobile
  }
}

.box-transforming-subtitle {
  position: relative;
  top: -10%;
  left: 2%;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
  font-family: "Poppins, sans-serif";
  text-align: center;
  margin-top: 2rem;
  max-width: 85%;
  font-size: 1.1rem; // Default font size

  span {
    display: block;
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    top: 0; // Reset top position for mobile
    left: 0; // Reset left position for mobile
    font-size: 1rem; // Smaller font size for mobile
    margin-top: 1rem; // Reduce spacing on mobile
    max-width: 90%; // Increase width for better readability on mobile
  }
}

.pros-button {
  margin-top: 2rem; // Add spacing above the button

  button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #21c07a;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-family: "Poppins, sans-serif";
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#21c07a, 10%);
    }

    img {
      width: 16px;
      height: 16px;
    }

    @media (max-width: 768px) {
      padding: 8px 16px; // Smaller padding for mobile
      font-size: 0.9rem; // Smaller font size for mobile
    }
  }

  @media (max-width: 768px) {
    margin-top: 1.5rem; // Reduce spacing on mobile
  }
}