.word-container {
  display: inline-block;
  position: relative;
  height: 60px; 
  overflow: hidden;
  left: 55%; 
}

.word-switcher {
  font-size: 100px;
  font-weight: bold;
 left: 31%;
  color: #19806C;
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 0;
  top: 15%;
  transform: translateY(-50%); 
  transition: transform 2s ease-out, opacity 0.5s ease-in;
}

.drop-in {
  opacity: 1;
  transform: translateY(0); 
}

@media screen and (max-width: 768px) {
  .word-container {
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    width: fit-content;
  }

  .word-switcher {
    font-size: 8vw; 
    left: 27%;
    top: 10%;
    transform: translateY(-50%);
    width: max-content;
  }
  .drop-in {
    opacity: 1;
    transform: translateY(0); 
  }
}