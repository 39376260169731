body {
  font-family: 'Poppins', sans-serif;
}

.title, .subtitle {
  font-family: 'Poppins', sans-serif;
}

.homepage {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  background: linear-gradient(100deg, #00271e, #000), url('https://betdevelopers.s3.us-east-1.amazonaws.com/bd-bg.png') no-repeat top center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 600vh;
  padding: 0;
  position: relative;
  z-index: 1;
}

.header-image {
  text-align: center;
  padding-top: 50px;
}

.logo-image {
  max-width: 100%; 
  height: auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: flex-start;  
  height: 100vh;  
  padding-right: 400px;
  padding-bottom: 30px;  
  text-align: left;
}

.title {
  position: relative;
  top: -62%; 
  left: 31%; 
  font-size: 100px; 
  font-weight: normal; 
  line-height: 1.1;
  margin: 0;
  justify-content: center;
}

.smart-data {
  position: relative;
  left: -1%;
}

.footer {
  font-size: 22px;
  font-weight: normal;
  color: #ffffff50;
  text-align: center; 
  position: relative;
  left: 30%;
}

.footer span {
  display: block; 
  line-height: 1.4;
}

.bottom-text {
  position: relative;
  text-align: left; 
  margin-left: 30px;
  left: 33%;
}

.our-partners {
  text-align: center;
  margin-top: 20px;
}

.our-partners h2 {
  position: relative;
  left: 7%;
  font-size: 20px;
  font-weight: normal;
  color: #ffffff50;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

.partner-logos {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 100px; 
  top: 20%;
  left: 10%;
}

.partner-logo {
  max-width: 150px;
  height: auto;
  transition: transform 0.3s ease;
}

.partner-logo:hover {
  transform: scale(1.1); 
}

.marketing-growth {
  position: relative;
  left: -48%;
  top: 55%;
  font-size: 58px;
  font-weight: 400;
  color: #fff;
  margin-top: 30px;
  white-space: pre-line;
  letter-spacing: 1px;
  line-height: 1.1;
}

.marketing {
  position: relative;
  left: -17%;
}

.growth {
  position: relative;
  left: -17%;
  color: #19806C;
  line-height: 1.1;
}

.features {
  position: relative;
  top: 2.7%;
  left: -18.5%;
  font-size: 13px;
  font-weight: normal;
  color: #ffffff50;
}

.extra-box {
  position: relative;
  top: 5%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.betting-tools {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
}

.betting-tool {
  position: relative;
  top: 45%;
  flex: 1;
  max-width: 410px;
  min-height: 460px; 
  background-color: #1a1a1a21;
  color: #fff;
  text-align: center;
  border-radius: 25px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.betting-tool img {
  width: 367px;
  height: auto;
  margin-bottom: 10px;
}

.betting-tool h3 {
  position: relative;
  // left: 28%;
  margin: 4px 22%;
  font-size: 32px;
  font-weight: normal;

  @media (min-width: 768px) {
    position:  relative !important;
    left: -20% !important;
    
    
  }
}


.betting-tool p {
  position: relative;
  left: 2%;
  font-size: 18px;
  color: #818181;
  font-weight: normal;
}

.ask-pros-button {
  display: flex;
  justify-content: center;
  margin-top: 220px;
}

.ask-pros-button button {
  position: relative;
  top: 130%;
  left: 2%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #23856D;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ask-pros-button button img {
  width: 20px; 
  height: auto;
}

.ask-pros-button button:hover {
  background-color: #1e6d58;
}

.winning-section {
  text-align: center;
  padding: 40px;
  background-color: transparent;
  margin-top: 100px; /* Reduced margin for smaller screens */
}

.winning-section h2 {
  font-size: 48px; /* Reduce from 62px */
  color: #ffffff;
  margin-bottom: 20px;
}

.winning-section h6 {
  font-size: 17px;
  color: #666;
  max-width: 800px;
  text-align: center;
  margin: 0 auto; /* Center align for all screen sizes */
  padding: 0 20px; /* Add padding for smaller screens */
}

/* Boxes container */
.boxes-container {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }
}

/* Box styles */
.box {
  position: relative;
  margin-top: 40px; /* Reduced margin for smaller screens */
  width: 100%;
  max-width: 460px;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #75757577;
  border-radius: 28px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.15);
}

/* Ensure images scale properly */
.box img {
  max-width: 100%;
  height: auto;
}

/* Specific adjustments for box-1 */
.box-1 img {
  margin-right: 100px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-right: 0px;
    width: 80%;
  }
}

/* Text Adjustments */
h3, h4 {
  font-size: 48px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: left;
  width: 90%;
  color: #8f8e8e;
}

/* Box-2 Adjustments */
.box-2 {
  max-width: 850px;
  min-height: 580px;
  width: 100%;
  border-radius: 28px;
  flex-direction: column;
  display: flex;
  justify-content: left;
}

.box-2 img {
  margin-left: 300px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-left: 0px;
    width: 80%;
  }
}

/* Adjusting heading inside box-2 */
h4 {
  font-size: 48px;
  text-align: left;
  margin-bottom: 40px;
  margin-top: 20px;
}

h4 span {
  display: block;
  font-size: 48px;
  color: #ffffff;
  line-height: 20px;
}

/* Box-3 Adjustments */
.box-3 {
  position: relative;
  left: 7%;
  max-width: 86%;
  min-height: 580px;
  width: 100%;
  border-radius: 28px;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    left: 0%;
    max-width: 100%;
  }
}

.box-3 img {
  display: block;
  margin-bottom: 10px;
  max-width: 100%;
  height: auto;
}

.custom-heading {
  font-size: 48px;
  font-weight: normal;
  left: 12%;
  color: #ffffff;
  white-space: nowrap;

  @media (max-width: 768px) {
    left: 0%;
    font-size: 32px;
  }
}

/* Adjusting text inside box-3 */
.winning-section h1 {
  font-size: 18px;
  font-weight: normal;
  margin-top: 50px;
  text-align: left;
  width: 100%;
  color: #8f8e8e;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 16px;
    padding: 0 20px;
  }
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .winning-section h2 {
    font-size: 36px;
  }

  .winning-section h6 {
    position: relative;
    font-size: 24px;
    max-width: 100%;
    margin-left: 20%;
  }

  .boxes-container {
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 90%;
  }

  h3, h4 {
    font-size: 36px;
  }

  h4 span {
    display: block;
    font-size: 28px;
    color: #ffffff;
    line-height: 20px;
  }

  
 

  p {
    font-size: 16px;
  }

  .box-3 {
    left: 0%;
    max-width: 100%;
  }

  .custom-heading {
    font-size: 36px;
    left: 0%;
  }
}

@media (max-width: 480px) {
  .winning-section h2 {
    font-size: 28px;
  }

  .winning-section h6 {
    font-size: 14px;
  }

   h4 {
    font-size: 28px;
  }

  
  

  p {
    font-size: 14px;
  }

  .custom-heading {
    font-size: 28px;
  }

  .box {
    padding: 15px;
  }
}


.box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .homepage {
    height: auto; 
    padding: 20px; 
  }

  .main-content {
    padding-right: 20px; 
    align-items: center; 
    text-align: center; 
  }

  .title {
    position: relative;
    font-size: 36px; 
    top: -70%; 
    left: 2%; 
    text-align: center; 
  }

  .footer, .bottom-text {
    font-size: 18px;
    position: relative;
    top: 4;
    left: 0; 
    text-align: center; 
  }
  .our-partners {
    position: relative;
    margin-top: -20px; 
    padding: 0 3%; 
    left: -5%;
  }

  .our-partners h2 {
    font-size: 26px;
    letter-spacing: 1px;
    text-align: center;
  }


  .partner-logos {
    position: relative;
    flex-direction: column; 
    gap: 20px; 
    top: 0; 
    left: 7%; 
  }

  .marketing-growth {
    position: relative;
    font-size: 32px; 
    left: 5%; 
    top: 0; 
    text-align: center; 
  }
  .marketing {
    position: relative;
    left: 4%; 
  }
  .growth {
    position: relative;
    left: 4%; 
  }
  .delivers {
    position: relative;
    margin-top: 10px;
    left: 24%; 
  }

  .betting-tools {
    
    flex-direction: column; 
    gap: 20px; 
  }

  .betting-tool {
    position: relative;
    margin-left: 5%;
    max-width: 90%; 
    min-height: auto; 
    top: 0; 
  }

  .ask-pros-button {
    margin-top: 40px; 
  }

  .ask-pros-button button {
    top: 0; 
    left: 0; 
  }

  .winning-section h2{
    margin-top: -40px; 
    font-size: 48px !important;
  }
  .winning-section h6 {
    position: relative !important;
    font-size: 14px;
    left: -13%;
    padding: 0 10px;
    
  }
  

  .boxes-container {
    flex-direction: column; 
    gap: 20px; 
  }

  .box, .box-1, .box-2, .box-3 {
    width: 100%; 
    max-width: 100%; 
    min-height: auto; 
    left: 0; 
  }

  .box-1 img, .box-2 img {
    margin: 0 auto; 
  }

  .custom-heading {
    font-size: 32px; 
    left: 0; 
    top: 0; 
  }
}
